<template>
  <!-- Page Content -->
  <div class="mx-10 my-2">
    <div v-if="isEditMode">
      <h2 class="my-4 text-4xl font-semibold">
        Edit Attendant
      </h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>
          Edit Attendant
        </p>
      </div>
    </div>

    <div v-else>
      <h2 class="my-4 text-4xl font-semibold">
        Add Attendant
      </h2>
      <div
        class="pb-2 flex items-center justify-between text-gray-600 border-b"
      >
        <p>
          Add an attendant to the database
        </p>
      </div>
    </div>
    <!-- Attendant Content -->
    <div class="flex flex-wrap">
      <div class="w-full lg:w-1/3 mt-5">
        <label
          class="w-full text-sm font-semibold"
        >
          App
        </label>
        <select
          v-model="form.socialApp"
          class="lb-admin-form-input rounded"
        >
          <option value="whatsapp">WhatsApp</option>
          <option value="telegram">Telegram</option>
          <option value="messenger">Messenger</option>
          <option value="wechat">WeChat</option>
          <option value="twitter">Twitter</option>
          <option value="viber">Viber</option>
          <option value="line">Line</option>
          <option value="email">Email</option>
        </select>
      </div>


      <div class="w-full lg:w-1/3 mt-5">
        <label
          class="w-full text-sm font-semibold"
        >
          Label
        </label>
        <input
          v-model="form.titleLabel"
          class="lb-admin-form-input rounded"
          placeholder="Eg: Sales"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label
          class="w-full text-sm font-semibold"
        >
          Name
        </label>
        <input
          v-model="form.fullName"
          class="lb-admin-form-input rounded"
          placeholder="Full Name"
          type="text"
        />
      </div>
      <div
        v-if="form.socialApp === 'whatsapp' || form.socialApp === 'viber'"
        class="w-full lg:w-1/3 mt-5"
      >
        <label
          class="w-full text-sm font-semibold"
        >
          Phone number
        </label>
        <VuePhoneNumberInput
          v-model="form.contactNumber"
          @update="updatePhoneNumber"
          class="phone-container"
          size="md"
          color="#aa9461"
          default-country-code="ZA"
        />
      </div>
      <div
        v-if="form.socialApp === 'telegram' || form.socialApp === 'line'"
        class="w-full lg:w-1/3 mt-5"
      >
        <label
          class="w-full text-sm font-semibold"
        >
          Username
        </label>
        <input
          v-model="form.username"
          class="lb-admin-form-input rounded"
          placeholder="username"
          type="text"
        />
      </div>
      <div
        v-if="form.socialApp === 'messenger' || form.socialApp === 'wechat' || form.socialApp === 'twitter'"
        class="w-full lg:w-1/3 mt-5"
      >
        <label
          class="w-full text-sm font-semibold"
        >
          User App Id
        </label>
        <input
          v-model="form.userAppId"
          class="lb-admin-form-input rounded"
          placeholder="User App Id"
          type="text"
        />
      </div>
      <div
        v-if="form.socialApp === 'email'"
        class="w-full lg:w-1/3 mt-5"
      >
        <label
          class="w-full text-sm font-semibold"
        >
          href
        </label>
        <input
          v-model="form.href"
          class="lb-admin-form-input rounded"
          placeholder="Href"
          type="text"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label
          class="w-full text-sm font-semibold"
        >
          Avatar Src
        </label>
        <UploadImage
          class="lg:w-1/3"
          v-model="form.avatar.src"
        />
      </div>
      <div class="w-full lg:w-1/3 mt-5">
        <label
          class="w-full text-sm font-semibold"
        >
          Avatar Atl
        </label>
        <input
          v-model="form.avatar.atl"
          class="lb-admin-form-input rounded"
          placeholder="Alt Tag"
          type="text"
        />
      </div>
    </div>

    <!-- Page Content Btn -->
    <div class="w-full mt-10 pt-5 border-t">
      <button
        v-if="!isEditMode"
        @click.prevent="addAttendant"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Add Attendant
      </button>

      <button
        v-if="isEditMode"
        @click.prevent="updateAttendant(agentID)"
        class="bg-primary px-4 py-2 text-white font-semibold rounded"
      >
        Update Agent
      </button>

      <button
        v-if="isEditMode && hasAccess('attendants', 'delete')"
        @click.prevent="deleteAttendant(agentID)"
        class="bg-red-600 ml-5 px-4 py-2 text-white font-semibold rounded"
      >
        Delete Attendant
      </button>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
import {socialCollection} from "@/firebase.js";
import permissionsMixin from "@/mixins/permissions";
import UploadImage from "../../components/admin/UploadImage";

import isNil from "lodash/isNil";
import VuePhoneNumberInput from 'vue-phone-number-input';

export default {
  name: "AdminAttendant",
  components: {
    VuePhoneNumberInput,
    UploadImage,
  },
  mixins: [permissionsMixin],
  data: () => ({
    agentID: null,
    form: {
      socialApp: null,
      titleLabel: null,
      fullName: null,
      contactNumber: null,
      e164ContactNumber: '',
      formatNationalContactNumber: '',
      username: null,
      userAppId: null,
      href: null,
      avatar: {
        src: "",
        atl: "",
      },
    },
  }),
  methods: {
    getAttendant(agentID) {
      socialCollection
        .doc(agentID)
        .get()
        .then((doc) => {
          this.form.socialApp = doc.data().socialApp;
          this.form.titleLabel = doc.data().titleLabel;
          this.form.fullName = doc.data().fullName;
          this.form.contactNumber = doc.data().contactNumber;
          this.form.e164ContactNumber = doc.data().e164ContactNumber;
          this.form.formatNationalContactNumber = doc.data().formatNationalContactNumber;
          this.form.username = doc.data().username;
          this.form.userAppId = doc.data().userAppId;
          this.form.href = doc.data().href;
          this.form.avatar = doc.data().avatar;

          this.agentID = doc.id;
        });
    },
    addAttendant() {
      const attendantData = {
        socialApp: this.form.socialApp || null,
        titleLabel: this.form.titleLabel || null,
        fullName: this.form.fullName || null,
        contactNumber: this.form.contactNumber || null,
        e164ContactNumber: this.form.e164ContactNumber,
        formatNationalContactNumber: this.form.formatNationalContactNumber,
        username: this.form.username || null,
        userAppId: this.form.userAppId || null,
        href: this.form.href || null,
        avatar: this.form.avatar || null,
        // createdAt: firebase.firestore.Timestamp.now(),
      };
      socialCollection
        .add(attendantData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Attendant Added",
          });
          this.$router.replace({ name: "admin-attendants" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error adding Attendant",
            text: error.message,
          });
          this.$router.replace({ name: "admin-attendants" });
        });
    },
    updatePhoneNumber(e) {
      this.form.e164ContactNumber = e.e164
      this.form.formatNationalContactNumber = e.formatNational
    },
    updateAttendant(agentID) {
      const attendantData = {
        socialApp: this.form.socialApp || null,
        titleLabel: this.form.titleLabel || null,
        fullName: this.form.fullName || null,
        contactNumber: this.form.contactNumber || null,
        e164ContactNumber:  this.form.e164ContactNumber,
        formatNationalContactNumber:  this.form.formatNationalContactNumber,
        username: this.form.username || null,
        userAppId: this.form.userAppId || null,
        href: this.form.href || null,
        avatar: this.form.avatar || null,
      };
      socialCollection
        .doc(agentID)
        .update(attendantData)
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            title: "Attendant Updated",
          });
          this.$router.replace({ name: "admin-attendants" });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error updating Attendant",
            text: error.message,
          });
          this.$router.replace({ name: "admin-attendants" });
        });
    },
    deleteAttendant(agentID) {
      var userConfirmedDelete = confirm(
        "Are you sure you want to delete this attendant?"
      );
      if (userConfirmedDelete) {
        socialCollection
          .doc(agentID)
          .delete()
          .then(() => {
            this.$notify({
              group: "global",
              type: "success",
              title: "Attendant Deleted",
            });

            this.$router.replace({ name: "admin-attendants" });
          })
          .catch((error) => {
            this.$notify({
              group: "global",
              type: "error",
              title: error,
            });
          });
      }
    },
  },
  computed: {
    isEditMode() {
      if (!isNil(this.$route.params.id)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (!isNil(this.$route.params.id)) {
      const agentID = this.$route.params.id;
      this.getAttendant(agentID);
    }
  },
};
</script>

<style>

.input-tel__input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #6b7280 !important;
}

.input-tel__input:focus {
  border: 2px solid #2563eb !important;
  box-shadow: rgb(37 99 235 / 70%) 0px 0px 0px 0.125rem !important;
}
.input-tel.is-valid .input-tel__label[data-v-e59be3b4] {
  color: #424542 !important;
}
.country-selector__input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: #6b7280 !important;
}

.country-selector__input:focus {
  border: 2px solid #2563eb !important;
  outline: 2px solid #2563eb !important;
}

.country-selector__label {
  color: #424542 !important;
}

.phone-container {
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

@media (min-width: 1024px) {
  .phone-container {
    width: 80%;
  }
}
.phone-container:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}
</style>
